import React, { useMemo, useState } from "react";
import { Avatar, Modal, Space, Tooltip } from "antd";
import { ReviewDto, SummaryDto, Voting } from "common/dist/dtos/review.dto";
import { formatDateMMMDDYYY } from "common/dist/util";
import {
    CheckCircleFilled,
    DislikeOutlined,
    LikeFilled,
    LikeOutlined,
    PlayCircleFilled,
    StarFilled,
    SyncOutlined
} from "@ant-design/icons";
import _ from "lodash";
import Player from "react-player/youtube";
import classNames from "classnames";
import { useMetricsContext } from "@/common/metrics";
import Link from "next/link";
import { shortName } from "../util";
import Image from "next/image";

export interface IReviewProps extends ReviewDto {
    tip?: React.ReactNode;
}

export function Review({ reviewer, message, date, voting, description, tip, youtubeVideoId }: IReviewProps) {
    const metrics = useMetricsContext();
    const youtubeVideo = (
        <div
            className="relative grid items-center justify-items-center text-3xl bg-cover rounded-md text-purple1 text-opacity-70 cursor-pointer shadow-lg border-solid border-gray-100 border"
            style={{
                width: "160px",
                height: "90px",
                backgroundImage: `url("https://img.youtube.com/vi/${youtubeVideoId}/mqdefault.jpg")`
            }}
            onClick={() => {
                metrics.log({ name: "view_review_video" });
                Modal.success({
                    icon: null,
                    title: null,
                    width: "640px",
                    bodyStyle: { padding: 0 },
                    maskClosable: true,
                    okButtonProps: { style: { display: "none" } },
                    content: (
                        <Player
                            width={"100%"}
                            url={`https://youtu.be/${youtubeVideoId}`}
                            playing={true}
                            controls={true}
                        />
                    )
                });
            }}
        >
            <PlayCircleFilled size={64} />
        </div>
    );
    return (
        <div className="p-1 sm:p-5 flex gap-5">
            <div>
                <Link href={`/profile/${reviewer.username}`}>
                    <a>
                        <Avatar icon={<Image src={reviewer.image} width={40} height={40} />} />
                    </a>
                </Link>
            </div>
            <div className="flex-1 flex flex-col gap-1">
                <Space>
                    <b>{shortName(reviewer.name)}</b>
                    {voting === Voting.Liked ? <LikeOutlined /> : <DislikeOutlined />}
                </Space>
                <div className="whitespace-pre-wrap break-words">{message}</div>
                <i>
                    <small>
                        {description ? `${description} - ` : ""} {formatDateMMMDDYYY(date)}
                    </small>
                </i>
                <div className="sm:hidden">{youtubeVideoId && youtubeVideo}</div>
                <div>{tip}</div>
            </div>
            <div className="hidden sm:block">{youtubeVideoId && youtubeVideo}</div>
        </div>
    );
}

export interface IReviewGroupProps {
    reviews: IReviewProps[];
}

export function ReviewGroup({ reviews }: IReviewGroupProps) {
    const [expand, setExpand] = useState(false);
    const tip =
        reviews.length > 1 ? (
            <a onClick={() => setExpand((e) => !e)}>{expand ? "Collapse" : `+${reviews.length - 1} from same buyer`}</a>
        ) : null;
    return (
        <div className={classNames("border-l-4 border-transparent border-solid", expand ? "border-gray-100" : "")}>
            <Review {...reviews[0]} tip={tip} />
            {expand && reviews.slice(1).map((x) => <Review {...x} key={x.date} />)}
        </div>
    );
}

export const Stat = (props: { icon: React.FC; text: React.ReactNode; description: string }) => (
    <Tooltip
        overlay={props.description}
        className="inline-flex gap-5 items-center bg-gray-50 rounded-lg px-4 py-1 cursor-pointer shadow-sm hover:shadow-md"
    >
        <span className="text-lg">
            <props.icon />
        </span>
        <span className="text-md font-semibold">{props.text}</span>
    </Tooltip>
);

export interface ISummaryProps extends SummaryDto {}

export function Summary(props: ISummaryProps) {
    const reviewGroups = useMemo(
        () =>
            _(props.reviews)
                .sortBy(
                    (r) => !r.youtubeVideoId,
                    (r) => -new Date(r.date).valueOf()
                )
                .groupBy((x) => x.reviewer.id)
                .values()
                .sortBy((g) => !g.some((r) => r.youtubeVideoId))
                .value(),
        [props.reviews]
    );

    return (
        <div>
            <div className="flex gap-5 pb-10 flex-wrap">
                <Stat icon={LikeFilled} text={props.liked} description="Positive reviews" />
                {props.successRate && (
                    <Stat
                        icon={CheckCircleFilled}
                        text={`${props.successRate}%`}
                        description="Percentage of positive reviews"
                    />
                )}
                <Stat icon={StarFilled} text={props.purchases} description="Number of purchases" />
                <Stat icon={SyncOutlined} text={props.repeatBuyers} description="Number of repeat buyers" />
            </div>
            <div>
                {reviewGroups.map((x, i) => (
                    <ReviewGroup reviews={x} key={i} />
                ))}
            </div>
        </div>
    );
}
