import React, { useEffect, useMemo, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useCommonContext } from "./util";
import { observer } from "mobx-react";
import { ServiceCard } from "./ServiceCard";
import { IServiceCardDto } from "common/dist/dtos/service/ServiceDto";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { UnderlinedHeader } from "./UnderlinedHeader";
import { ICategoryDto } from "common/dist/dtos/ICategoryDto";
import _ from "lodash";

export interface ICarouselProps<T> {
    id: string;
    items: Array<T>;
    header: string;
    subtitle?: string;
    smallHeader?: boolean;
    height: `${string}px`;
    keyPrefix: string;

    render(item: T): React.ReactNode;
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export interface IHeaderProps {
    smallHeader: boolean;
    header: string;
    subtitle: string;
}

export function Header({ smallHeader, header, subtitle }: IHeaderProps) {
    return (
        <div>
            <UnderlinedHeader className={smallHeader ? "text-xl" : "text-3xl"}>{header}</UnderlinedHeader>
            {subtitle && <p className="mt-2 text-sm-2">{subtitle}</p>}
        </div>
    );
}

export function CardCarousel<T>({ items, keyPrefix, render, header, subtitle, smallHeader, id }: ICarouselProps<T>) {
    const size = useWindowSize();
    const visibleSlides = useMemo(() => {
        if (size.width >= 1280) {
            return 5;
        } else if (size.width >= 1024) {
            return 4;
        } else if (size.width >= 768) {
            return 3;
        } else if (size.width >= 640) {
            return 2;
        } else {
            return 1;
        }
    }, [size]);
    return (
        <CarouselProvider
            visibleSlides={visibleSlides}
            totalSlides={items.length}
            naturalSlideWidth={220}
            naturalSlideHeight={440}
            isIntrinsicHeight={true}
        >
            <div>
                <div className="flex mb-10 items-center" id={id}>
                    <div className="flex-1">
                        <Header header={header} smallHeader={smallHeader} subtitle={subtitle} />
                    </div>
                    <div className="flex gap-1 h-16">
                        <ButtonBack className="focus:border-none focus:outline-none px-2">
                            <div className="shadow-lg cursor-pointer h-9 w-9 rounded-full flex justify-center items-center">
                                <LeftOutlined style={{ color: "#CDD0D9" }} />
                            </div>
                        </ButtonBack>
                        <ButtonNext className="focus:border-none focus:outline-none px-2">
                            <div className="shadow-lg cursor-pointer h-9 w-9 bg-purple1 rounded-full flex justify-center items-center">
                                <RightOutlined style={{ color: "#fff" }} />
                            </div>
                        </ButtonNext>
                    </div>
                </div>
                <Slider>
                    {items.map((x, i) => (
                        <Slide tag="div" index={i} key={`${keyPrefix}-${i}`}>
                            <div className="flex justify-center">{render(x)}</div>
                        </Slide>
                    ))}
                </Slider>
            </div>
        </CarouselProvider>
    );
}

export interface ICategoryCarouselProps {
    category: ICategoryDto;
}

export interface IServiceCarouselProps {
    services: IServiceCardDto[];
    header: string;
    subtitle?: string;
    id: string;
}

// TODO: better this
export const ServiceCarousel = (props: IServiceCarouselProps) => {
    return (
        <CardCarousel
            id={props.id}
            height="430px"
            header={props.header}
            subtitle={props.subtitle}
            items={props.services}
            render={(x) => <ServiceCard dto={x} />}
            keyPrefix={""}
        />
    );
};

export const CategoryCarousel = observer((props: ICategoryCarouselProps) => {
    const { fetchServicesFromCategory } = useCommonContext();
    const [fetchServices] = useState(() => fetchServicesFromCategory(props.category.id));
    return fetchServices.match({
        resolved: (services) => (
            <ServiceCarousel
                header={props.category.name}
                services={_.sortBy(services, (s) => -s.orders)}
                id={props.category.slug}
                subtitle={props.category.description}
            />
        )
    });
});
