import classNames from "classnames";
import { PropsWithChildren } from "react";

export interface IUnderlinedHeader extends PropsWithChildren<{}> {
    className: string;
}

export function UnderlinedHeader(props: IUnderlinedHeader) {
    return (
        <h2 className={classNames("font-medium mb-0", props.className)}>
            <span className="inner-underline items-center">{props.children}</span>
        </h2>
    );
}
