import classNames from "classnames";
import React, { PropsWithChildren } from "react";

export type IRatioFixerProps = PropsWithChildren<{
    ratio: number;
    className?: string;
}>;

export function RatioFixer(props: IRatioFixerProps) {
    return (
        <div className={classNames("w-100 relative", props.className)} style={{ paddingTop: `${props.ratio * 100}%` }}>
            <div className="absolute left-0 top-0 bottom-0 right-0">{props.children}</div>
        </div>
    );
}
