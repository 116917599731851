import { endpoints } from "@/api";
import { IServiceDto } from "common/dist/dtos/service/ServiceDto";
import { makeAutoObservable } from "mobx";
import { task } from "./util";

export class ServiceStore {
    constructor(readonly service: IServiceDto) {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    fetchSummary = task(() => endpoints.service.getSummary(this.service.id));
}
