import React from "react";
import { Breadcrumb } from "antd";
import Link from "next/link";
import { HomeOutlined } from "@ant-design/icons";

const BreadCrumb = ({ items }) => (
    <Breadcrumb>
        <Breadcrumb.Item>
            <Link href="/">
                <a>
                    <HomeOutlined />
                </a>
            </Link>
        </Breadcrumb.Item>
        {items &&
            items.map((item: { title: string; href: string }, index: number) => (
                <Breadcrumb.Item key={index}>
                    {item.href ? (
                        <Link href={item.href}>
                            <a>{item.title}</a>
                        </Link>
                    ) : (
                        item.title
                    )}
                </Breadcrumb.Item>
            ))}
    </Breadcrumb>
);

export default BreadCrumb;
