import { Input } from "antd";

export interface IAffiliateLinkProps {
    link: string;
}

export function AffiliateLink(props: IAffiliateLinkProps) {
    return (
        <div className="grid gap-5">
            <b>Share & Earn 20%</b>
            <Input value={props.link} />
            <small>If anyone buys a service within 7 days of clicking your link, you gain 20% cashback.</small>
        </div>
    );
}
