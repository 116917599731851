import { BackTop, Button } from "antd";
import { ToTopOutlined } from "@ant-design/icons";

export function TopBack() {
    return (
        <BackTop duration={200} style={{ right: "38px", bottom: "100px" }}>
            <Button
                type="primary"
                style={{ width: "45px", height: "fit-content", aspectRatio: "1" }}
                icon={<ToTopOutlined style={{ fontSize: "26px" }} />}
            />
        </BackTop>
    );
}
