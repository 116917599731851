import classNames from "classnames";
import FilePreview from "../FilePreview";
import { RatioFixer } from "../RatioFixer";
import { isImage } from "../util";
import Player from "react-player/youtube";

export interface IPreviewSectionProps {
    max?: boolean;
    explainerVideoId?: string;
    thumbnails: string[];
    samples: string[];
}

export function PreviewSection(props: IPreviewSectionProps) {
    const [firstThumbnail, secondThumbnail] = props.thumbnails;
    const [firstSample, secondSample] = props.samples.filter(isImage);

    const Sample = (props: { url: string }) => {
        return (
            <FilePreview
                preview={true}
                src={props.url}
                className="absolute rounded-md object-cover"
                width="100%"
                height="100%"
            />
        );
    };

    const Thumbnail = (props: { url: string; className?: string }) => (
        <RatioFixer ratio={1.4772} className={props.className}>
            <div
                className="h-full rounded-md bg-cover bg-center w-full"
                style={{ backgroundImage: `url("${props.url}")` }}
            />
        </RatioFixer>
    );

    const ColumnPlaceholder = (props: { className?: string }) => (
        <RatioFixer ratio={1.4772} className={props.className} />
    );

    const firstColumn = firstThumbnail ? (
        <Thumbnail url={firstThumbnail} className={classNames({ "hidden lg:block": !!props.explainerVideoId })} />
    ) : (
        <ColumnPlaceholder />
    );

    const secondColumn = firstSample ? (
        <div className="grid grid-flow-row items-stretch gap-2 sm:gap-5">
            <Sample url={firstSample} />
            {secondSample && <Sample url={secondSample} />}
        </div>
    ) : (
        <ColumnPlaceholder />
    );

    const thirdColumn = secondThumbnail ? (
        <Thumbnail className={classNames({ "hidden lg:block": !props.max })} url={secondThumbnail} />
    ) : (
        <RatioFixer ratio={1.4772} className="hidden lg:block" />
    );

    const secondPart = props.explainerVideoId ? (
        <div>
            <Player
                width="100%"
                style={{ height: "100%" }}
                url={`https://youtu.be/${props.explainerVideoId}?rel=0`}
                controls={true}
                playing={true}
                muted={true}
            />
        </div>
    ) : (
        <>
            {secondColumn}
            {thirdColumn}
        </>
    );

    return (
        <div className="grid grid-flow-col gap-2 sm:gap-5">
            {firstColumn}
            {secondPart}
        </div>
    );
}
