import React from "react";
import { Space } from "antd";
import { StarOutlined } from "@ant-design/icons";
import Link from "next/link";
import Image from "next/image";
import { IServiceCardDto } from "common/dist/dtos/service/ServiceDto";

export interface IServiceCardProps {
    dto: IServiceCardDto;
    size?: "small" | "normal";
}

export function ServiceCard({ dto, size = "normal" }: IServiceCardProps) {
    const { name, thumbnail, orders, startingAt } = dto;
    const width = `${size === "normal" ? "220" : "176"}px`;
    const height = `${size === "normal" ? "325" : "260"}px`;
    return (
        <Link href={dto.url}>
            <a
                className="relative text-black hover:text-black mb-5 border border-gray-200 border-solid overflow-hidden rounded-lg cursor-pointer shadow-lg hover:shadow-xl bg-white"
                style={{ width }}
            >
                <Image
                    src={thumbnail}
                    className="object-cover object-center"
                    width={width}
                    height={height}
                    alt={`${dto.name}`}
                />
                <div
                    className="absolute inset-0 grid p-3 text-white items-end"
                    style={{
                        backgroundImage: "linear-gradient(to top, rgba(16,14,37,1.0), rgba(16,14,37,0.0) 35%)"
                    }}
                >
                    <div className="overflow-hidden">
                        <div className="font-medium overflow-ellipsis overflow-hidden whitespace-pre" title={name}>
                            {name}
                        </div>
                        <div className="flex">
                            <Space className="flex-1 text-sm">
                                <StarOutlined />
                                {orders}
                            </Space>
                            <b className="text-lg">${startingAt}</b>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
}
